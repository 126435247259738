import 'lightgallery.js';


const lgContainers = document.querySelectorAll('.has-lightgallery');

if(lgContainers) {
    lgContainers.forEach(el => {
        lightGallery(el, {
            selector: 'figure img',
            download: false,
        });
    }) 
}